.footer {
    /*background-color: #1a202c;*/
    color: #ffffff;
    padding: 3rem 0 1rem;
    font-family: Arial, sans-serif;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 2rem;
}

.footer-section {
    flex: 1;
    min-width: 200px;
    margin-bottom: 2rem;
}

.footer-section h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.footer-section h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.footer-section ul {
    list-style-type: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
}

.social-links {
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.social-links a {
    color: #ffffff;
    font-size: 1.5rem;
    transition: color 0.3s ease;
}

.social-links a:hover {
    color: #3b82f6;
}

.footer-bottom {
    text-align: center;
    padding-top: 2rem;
    margin-top: 2rem;
    border-top: 1px solid #2d3748;
}

@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
    }

    .footer-section {
        margin-bottom: 2rem;
    }
}