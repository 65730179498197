/* Navbar styles */
.fixed-navbar {

    top: 0;
    left: 0;
    width: 100%; /* Full-width navbar */
    height: 40px; /* Fixed height for the navbar */

    z-index: 1000; /* Keeps the navbar on top of all other content */
    display: flex;
    justify-content: left;
    align-items: center;

}


.no-scrollbar::-webkit-scrollbar {
    display: none;
}
.no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}


/* Aligns the logo and text horizontally */
.logo-link {
    display: flex;
    align-items: center;
    text-decoration: none;
}

/* Adjusts the logo size */
.logo-image {
    width: 40px;    /* Adjust as needed */
    height: auto;
    margin-right: 8px; /* Space between the logo and text */
}

/* Optional: Style for the text */
.logo-link span {
    font-size: 1.5rem;
    font-weight: bold;
    color: #000; /* Change to your theme color */
}
