@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --black: #171321;
  --dkblue: #0d314b;
  --plum: #4b0d49;
  --hotmag: #ff17e4;
  --magenta: #e310cb;
  --aqua: #86fbfb;
  --white: #f7f8fa;
  --font-size: 1.3rem;
  --mono: "Oxygen mono", monospace;
  --sans: Oxygen, sans-serif;
}

html {
  box-sizzing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--sans);
  background-color: var(--black);
  color: var(--white);
  font-size: var(--font-size);

  overflow-x: hidden; /* Prevent horizontal scroll */
}

html, body {
  width: 100%;
  overflow-x: hidden;
}


h1, h2, h3 {
  margin: 0;
}

a {
  color: var(--magenta);
}

a:hover {
  color: var(--hotmag);
  text-decoration: none;
}


/* background color divs */
.section-plum{
  background-color: var(--plum);
}
.section-dkblue{
  background-color: var(--dkblue);
}
.section-plum-faq{
  background: linear-gradient(135deg, #6d1b7b, #8e247e); /* Subtle gradient to add depth */
}

.gradient{
  background: linear-gradient(90deg, rgba(255, 23, 228, 1) 0%, rgba(134, 251, 251, 1) 100%);
  height: 2px;
}

#intro {
  padding: 4rem 1rem 10rem 1rem;
  margin: 0 auto;
  max-width: 1200px;
}

#intro p {
  font-size: 1.1rem;
  line-height: 1.5;
  margin: 15px 0;
}

#intro .name {
  font-family: var(--mono);
}

.name span {
  color: var(--aqua);
  font-size: 4rem;
  font-family: var(--sans);
  display: block;
  font-weight: 300;
}

#intro h2 {
  font-size: 4rem;
}



#contact {
  text-align: center;
  background-color: var(--plum);
  width: 400px;
  padding: 4rem 0;
  margin: 0 auto;
}


#contact p:last-child {
  margin-top: 3rem;
}




nav{
  font-family: var(--mono);
  font-size: 80%;
  padding: 4rem 1rem;
}

nav h1 a{
  font-family: var(--sans);
}
nav h1{
  font-size: 150%;
}

nav li h1 span:first-child{
  font-size: 100%;
  color: var(--aqua);
}

nav li h1 span:first-child:hover{
  color: var(--magenta);
}
nav ul{
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;

}

nav a{
  text-decoration: none;
  color: var(--white);
  display: block;
}
nav a:hover{
  color: var(--magenta);
}
nav li:first-child{
  flex-basis: 100%;
  text-align: center;
}
.icon{
  font-size: 150%;
  color: var(--aqua);
}
.textHide{
  display: none;
}
.icon:hover{
  color: var(--magenta);
}


.button{
  background-color: var(--magenta);
  color: var(--white);
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-family: var(--mono);
  text-decoration: none;
}
.button:hover{
  color: var(--white);
  background-color: var(--hotmag);
}



@media (min-width: 850px){

  nav{
    max-width: 1200px;
    margin: 0 auto;
  }


  nav li:first-child{
    flex-basis: auto;
    text-align: left;
    margin-right: auto;
  }
}



/* projects section */
#projects{
  padding: 4rem 1rem;
}
img{
  width: 100%;
}

#projects h2{
  font-size: 2.5rem;
  margin-bottom: calc(2.5rem * 1.5);
}
#projects h3{
  color: var(--aqua);
}
#projects h4{
  font-size: 1rem;
  margin: 0;
  font-family: var(--mono);
}
#projects p{
  background-color: var(--black);
  padding: 1rem;
  border-radius: 0.5rem;
  color: var(--white);
  font-size: 1rem;
  line-height: 1.5;
  padding-left: 50px;
}
#projects ul{
  list-style-type: none;
  font-size: 1rem;
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  margin: 0;
  padding: 0;
  justify-content: flex-start;
}
#projects img{
  margin: 2rem 0 4rem 0;
  border-left: 2px solid var(--aqua);
  border-top: 1px solid var(--aqua);
  border-radius: 25px;
  padding: 1rem;
}

@media (min-width: 800px){
  #projects{
    max-width: 1200px;
    margin: 0 auto;
  }
  article{
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 1rem;
  }

  #projects img{
    grid-row: 1/2;
    grid-column: 1/6;
  }
  .text{
    grid-column: 5/11;
    grid-row: 1/2;
    order: 2;
    text-align: right;
  }


  #projects ul{
    justify-content: flex-end;
  }

  #projects .reverse .text{
    grid-row: 1/2;
    order: 2;
    text-align: left;
    grid-column: 1/7;
  }
  #projects .reverse p{

    padding: 1rem;
    padding-right: 50px;

  }

  #projects .reverse img{
    grid-column: 6/11;
    grid-row: 1/2;
  }

  #projects .reverse ul{
    justify-content: flex-start;
  }



}





footer{
  text-align: center;
  padding: 4rem 0;
}
footer ul{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  list-style-type: none;
  gap: 2rem;
  font-size: 3rem;
  padding: 0;
  margin: 2rem 0;
}






.coding-platforms {
  list-style-type: none;
  padding: 0;
  margin: 1.5rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.75rem;
}

.coding-platforms li {
  margin: 0;
}

.coding-platforms a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--white);
  background-color: var(--dkblue);
  border: 1px solid var(--aqua);
  border-radius: 1.75rem;
  padding: 0.35rem 0.9rem;
  transition: all 0.3s ease;
  font-size: 1rem;
}

.coding-platforms a:hover {
  background-color: var(--plum);
  transform: translateY(-2px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

.platform-icon {
  display: inline-block;
  width: 1.75rem;
  height: 1.75rem;
  line-height: 1.75rem;
  text-align: center;
  background-color: var(--aqua);
  color: var(--dkblue);
  border-radius: 50%;
  margin-right: 0.5rem;
  font-weight: bold;
  font-family: var(--mono);
  font-size: 0.9rem;
}

.platform-name {
  font-family: var(--sans);
}

@media (max-width: 600px) {
  .coding-platforms {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .coding-platforms a {
    width: auto;
    justify-content: flex-start;
  }
}







.dub-gradi{
  background: linear-gradient(135deg, #0d314b, #193a55, #1c4b65);
}







.services-section {
  padding: 4rem 0;
  /*background: linear-gradient(to bottom, #f0f8ff, #ffffff);*/
  /*background: linear-gradient(135deg, #0d314b, #193a55, #1c4b65);*/
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;

  width: 100%; /* Ensure container fits within viewport */
  box-sizing: border-box; /* Ensure padding doesn't cause overflow */
}




.section-title {
  font-size: 2.5rem;
  text-align: center;
  /*color: #333;*/
  color: #f7f8fa;
  margin-bottom: 3rem;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;


  box-sizing: border-box; /* Include padding in element size */
}

.service-item {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;

  padding: 1.5rem; /* Adjusted for better spacing */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  box-sizing: border-box; /* Ensures padding doesn't add to width */
  overflow: hidden; /* Prevents content from spilling out */
}

.service-item:hover {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.service-icon {
  font-size: 2.5rem;
  /*color: #3b82f6;*/
  color: var(--magenta);
  margin-bottom: 1rem;
}

.service-title {
  font-size: 1.25rem;
  color: #1f2937;
  margin-bottom: 0.5rem;
}

.service-description {
  font-size: 1rem;
  color: #4b5563;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .services-grid {
    grid-template-columns: 1fr;
  }
}












.dubbing-customizer {
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 2rem auto;
}

.dubbing-customizer h2 {
  color: #333;
  margin-bottom: 1.5rem;
  text-align: center;
}

.customizer-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: #555;
}

select, input[type="number"] {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.radio-group {
  display: flex;
  gap: 1rem;
}

.radio-group label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.language-selector {
  margin-bottom: 0.5rem;
}

.selected-languages {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.language-tag {
  background-color: #e9ecef;
  padding: 0.25rem 0.5rem;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.language-tag button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: #6c757d;
}

.price-quote {
  background-color: #e9ecef;
  padding: 1.5rem;
  border-radius: 8px;
  text-align: center;
  margin-top: 2rem;
}

.price-quote h3 {
  color: #28a745;
  margin-bottom: 0.5rem;
}

.quote-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.quote-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .customizer-grid {
    grid-template-columns: 1fr;
  }

  .container{
    max-width: 800px;
  }
}














.team-section {
  width: 100%;
  overflow: hidden;
  text-align: center;
}

.scrolling-row {
  display: flex;
  overflow: hidden;
  margin: 20px 0;
}

.scroll-content {
  display: flex;
  animation: scroll linear infinite;
}

.scrolling-row.left-to-right .scroll-content {
  animation-direction: reverse;
}

.scrolling-row.paused .scroll-content {
  animation-play-state: paused;
}

.team-member {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  width: 300px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 0 15px;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.team-member .profile-pic {
  flex-shrink: 0;
  margin-right: 15px;
}

.team-member .profile-pic img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.team-member .member-info {
  text-align: left;
  color: #1a202c;
}

.team-member h3 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.team-member .role {
  margin: 5px 0;
  font-size: 14px;
  color: #666;
}

.team-member .location {
  font-size: 12px;
  color: #999;
}

/* Adjust animation speed dynamically based on the speed prop */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}


