.faq-container1 {
    padding: 50px 20px;
    background: linear-gradient(135deg, #6d1b7b, #8e247e); /* Subtle gradient to add depth */
}

.faq-container2 {
    max-width: 900px;
    margin: 0 auto;
    padding: 0 20px;
}

.faq-title {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 32px;
    color: #ffffff;
    text-align: center; /* Centered title */
}

.faq-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 10px;
}

.faq-question-button {
    width: 100%;
    padding: 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
    color: #ffffff;
    transition: background-color 0.3s ease; /* Subtle hover effect */
}

.faq-question-button:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Light background on hover */
}

.faq-question {
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
    margin: 0;
}

.faq-icon {
    transition: transform 0.2s ease;
    color: #ffffff;
}

.faq-icon.open {
    transform: rotate(180deg);
}

.faq-answer {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease-out; /* Smoother animation with height */
}

.faq-answer.open {
    height: auto; /* Let it adjust naturally */
    transition: height 0.5s ease-in;
}

.faq-answer-content {
    padding: 0 0 24px;
    padding-right: 48px;
    color: #f0f0f0;
    line-height: 1.6;
}
